<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12>
                    <v-text-field v-model="nameCapitalize" :rules="[rules.required, rules.min(4)]" :label="$t('Como você quer ser chamado')" required />
                </v-flex>
                <v-flex xs12 md12 sm12>
                    <v-text-field :label="$t('E-mail')" v-model="item.email" />
                </v-flex>
                <v-flex xs12 md12 sm12>
                    <v-text-field :label="$t('Telefone')" v-model="item.phone" />
                </v-flex>
                <v-flex xs12 md12 sm12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        nameCapitalize: {
            get: function () {
                return this.item.name;
            },
            set: function (data) {
                this.item.name = this.rules.filters.capitalized(data);
            },
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                name: "",
                email: "",
                phone: "",
            },
            item: { ...this.itemClean },
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>